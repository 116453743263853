<template>
  <div class="id-protection">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path">
          <breadcrumbs :crumbs="breadcrumbs" />
        </div>

        <verification
          v-if="verification"
          :coordVerified="coordVerified"
          :companyBus="companyBus"
          :info="verification"
          @success="success"
          @nextPage="goToAddBrand"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Verification from "../components/company/Verification.vue";
import Vue from "vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "id-protection",
  components: {
    Verification,
    Breadcrumbs
  },
  head() {
    return {
      title: "ID Protection"
    };
  },
  data() {
    return {
      verification: null,
      coordVerified: null,
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["ID Protection", null]
      ],
      companyBus: new Vue()
    };
  },
  mounted() {
    this.$store.commit("setTitle", "Identity Protection");
    // get saved data
    this.$axios
      .post("/company/get-verification")
      .then((response) => {
        let data = response.data;
        console.log(data);
        this.coordVerified = data.coordVerified;
        this.verification = data.verificationInfo;

        if (data.coordVerified) {
          // window.scrollTo(0, 0);
          // this.$refs.alert.open({
          //   title: "ID Verification",
          //   msg: "Thank you for verifying your identity. We will now redirect you to the next step in your event management journey.",
          //   confirm: "Okay",
          //   closeable: true,
          //   onConfirm: () => {
          //     this.goToAddBrand();
          //   }
          // });
          // this.$store.state.bus.$emit("alert", ModalContent.idVerification);
          // setTimeout(() => {
          //   this.$router.push("/brands/add-brand");
          // }, 5000);
        }
      })
      .catch((error) => console.log(error));
  },
  methods: {
    goToAddBrand() {
      this.$router.push("/brands/add-brand");
    },
    success(data) {
      if (data) {
        this.verification = data.verificationInfo;
        this.coordVerified = data.coordVerified;

        window.scrollTo(0, 0);

        // this.$store.state.bus.$emit("alert", ModalContent.idVerification);

        this.$store.commit("updateUserProperty", {
          property: "coordVerified",
          value: true
        });
      }
    }
  }
};
</script>

<style></style>
