<template>
  <div id="Content">
    <div class="content-inner">
      <div class="section" v-if="seats_event_key">
        <div id="manage" class="design"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ManageReservedSeats",
  data() {
    return {
      apiKey: '',
      seats_event_key: null,
    };
  },
  async mounted() {
    await this.$axios
      .get(`/get-event/${this.$route.params.url}`)
      .then(response => {
        this.seats_event_key = response.data.seats_event_key;
        this.apiKey = this.$store.state.user.seats_secret_api_key;
        this.$forceUpdate();
      });

    this.chart = new seatsio.EventManager({
      divId: 'manage',
      event: this.seats_event_key,
      secretKey: this.apiKey,
      mode:"manageForSaleConfig"
    });
    this.chart.render();
  }
}
</script>

<style scoped>
.design {
  width: 100%;
  height: 80vh;
  margin-bottom: 20px;
  border: 1px solid #48bb8b;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>