<template>
  <div id="Content">
    <div class="content-inner">
      <div class="row" v-if="!event.chart_key">
        <div class="col half flex center">
          <FormulateInput
            type="multiSelect"
            label="Clone chart from your previous event"
            placeholder="Select"
            :options="chartList"
            name="clonedChart"
            @input="cloneChart"
            autocomplete="false"
          />
        </div>
      </div>
      <div class="section" v-if="apiKey">
        <div id="designer" class="design"></div>
        <SeatsioDesigner
          v-if="event.chart_key"
          :secretKey="apiKey"
          region="na"
          :chartKey="event.chart_key"
          canvasColorScheme="dark"
          mode="normal"
          divId="designer"
        />
        <SeatsioDesigner
          v-else
          :secretKey="apiKey"
          region="na"
          :onChartCreated="chartCreated"
          canvasColorScheme="dark"
          divId="designer"
        />
      </div>
      <FormulateInput
        v-if="event.chart_key && !event.reserve_event_created"
        class="button"
        type="button"
        name="Publish plan"
        @click="publish"
      />

      <FormulateInput
        v-if="event.reserve_event_created"
        class="button"
        type="button"
        name="Publish changes"
        @click="publishChanges"
      />
      <FormulateInput
        v-if="event.reserve_event_created"
        class="button"
        type="button"
        name="Manage plan"
        @click="goToManage"
      />
    </div>
  </div>
</template>

<script>

import {SeatsioDesigner, SeatsioSeatingChart} from "@seatsio/seatsio-vue";
import Vue from "vue";

export default {
  components: {
    SeatsioDesigner,
    SeatsioSeatingChart
  },
  name: "ReservedSeats",
  data() {
    return {
      apiKey: '',
      workspaceKey: '',
      event: [],
      newDesign: true,
      editDesign: false,
      chartList: [],
    };
  },
  methods: {
    cloneChart(value) {
      if (value[0]) {
        this.$axios.post('/reserved-seats/clone-chart', {
          event_id: this.event._id,
          chart_id: value[0],
        }).then(response => {
          if (response.data.error) {
            this.$toast(response.data.error.message, {type: "error"});

          } else {
            this.event.chart_key = response.data;
            location.reload();
          }
        });
      }
    },
    getAllCharts() {
      this.$axios.post('/reserved-seats/list', {
        companyUrl: this.event.companyUrl
      }).then(response => {
        if (response.status == 200) {
          response.data.forEach(t => {
            this.chartList.push({
              value: t.chart_key,
              label: t.name
            });
          });
        }
      });
    },
    publish() {
      this.$axios.post('/reserved-seats/publish', {
        event_id: this.event._id,
      }).then(response => {
        if (response.data.error) {
          this.$toast(response.data.error, {type: "error"});
        } else if (response.status == 200) {
          this.event.reserve_event_created = true;
          this.$toast("Chart was published");
          this.$forceUpdate();
          this.$router.push("/dashboard");
        } else {
          this.$toast(response.data, {type: "error"});
        }
      });
    },
    goToManage() {
      this.$router.push(`/manage-reserved-seats/${this.$route.params.url}`)
    },
    publishChanges() {
      this.$axios.post('/reserved-seats/publish-changes', {
        event_id: this.event._id,
      }).then(response => {
        if (response.data.error) {
          this.$toast(response.data.error, {type: "error"});
        } else if (response.status == 200) {
          this.$toast("Chart was updated");
          this.$forceUpdate();
          this.$router.push("/dashboard");
        } else {
          this.$toast(response.data, {type: "error"});
        }
      });
    },
    chartCreated(chartKey) {
      //save chart key to event
      this.$axios.post("/reserved-seats/add-chart-key", {
        url: this.event.url,
        chartKey: chartKey,
      }).then(response => {
        if (response.status == 200) {
          this.event.chart_key = chartKey;
          this.$toast("Chart created");
          this.$forceUpdate();
        } else {
          this.$toast(response.data, {type: "error"});
        }
      });
    },
  },
  async created() {
    await this.$axios
      .get(`/get-event/${this.$route.params.url}`)
      .then(response => {
        this.event = response.data;
        this.workspaceKey = this.$store.state.user.seats_api_key;
        this.apiKey = this.$store.state.user.seats_secret_api_key;
        this.getAllCharts();
        this.$forceUpdate();
      });
  }
}
</script>

<style scoped>
.design {
  width: 100%;
  height: 80vh;
  margin-bottom: 20px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>