<template>
  <div class="verification-wrapper">
    <div class="verification-container" v-if="incomplete">
      <div class="verification-header">
        <h2 class="verification-title">Why do we require this step?</h2>
        <p class="verification-header-msg b1">
          Identity Protection was established in 2020 to ensure all the events
          on INCREDEVENT are legitimate.
        </p>
        <p class="verification-header-msg b1">
          Given that there are often large money transfers between INCREDEVENT
          and Planners this step ensures that there is no fraud committed using
          your name or the name of another planner.
        </p>
        <p class="verification-header-msg b1">
          If you have an upcoming event we can contact your next venue to verify
          your identity.
        </p>
        <p class="verification-header-msg b1">
          If you are looking for the quickest verification you can fill out a
          personal verification form.
        </p>
        <p class="verification-header-msg b1">
          Verification codes are only used when you are working with an
          authorized INCREDEVENT representative.
        </p>
        <p class="verification-header-msg b1">
          Thank you for understanding and making the event management industry
          more trustworthy and secure.
        </p>
      </div>
      <div class="verification-body">
        <!-- <p class="verification-title-text b1">
        <i>All fields are Required</i>
      </p> -->
        <div class="verification-field-ctn row">
          <div class="col flex center full">
            <div class="info-input-wrapper">
              <diyobo-input
                type="dropdown"
                label="Verification Method"
                placeholder="Select Verification Method"
                required
                v-model="verificationType"
                :options="verificationTypes"
                :val="verificationType"
                :error="error('verificationType')"
              />
            </div>
          </div>
        </div>
        <div
          v-if="verificationType === 'venue'"
          class="verification-field-ctn row"
        >
          <div class="info-input-wrapper col half">
            <diyobo-input
              type="text"
              label="Venue Name For First Event"
              placeholder="Venue Name"
              required
              v-model="venueFormValues.venueName"
              :val="venueFormValues.venueName"
              :error="venueFormErrors.venueName"
              @keyup="validateAt('venueName')"
            />
          </div>
          <div class="info-input-wrapper col half">
            <diyobo-input
              type="text"
              label="Venue Website For First Event"
              placeholder="Venue Website"
              required
              v-model="venueFormValues.venueWebsite"
              :val="venueFormValues.venueWebsite"
              :error="venueFormErrors.venueWebsite"
              @keyup="validateAt('venueWebsite')"
            />
          </div>
          <div class="info-input-wrapper col half">
            <diyobo-input
              type="text"
              label="Venue Manager's Name"
              placeholder="Manager Name"
              required
              v-model="venueFormValues.managerName"
              :val="venueFormValues.managerName"
              :error="venueFormErrors.managerName"
              @keyup="validateAt('managerName')"
            />
          </div>
          <div class="info-input-wrapper col half">
            <diyobo-input
              id="verification-phone-number"
              type="text"
              label="Venue Manager's Phone Number"
              placeholder="Venue Phone Number"
              required
              v-model="venueFormValues.venuePhone"
              :val="venueFormValues.venuePhone"
              :error="venueFormErrors.venuePhone"
              @keyup="validateAt('venuePhone')"
            />
          </div>
        </div>
        <div
          v-if="verificationType === 'personal'"
          class="verification-field-ctn row"
        >
          <div class="info-input-wrapper col half">
            <diyobo-input
              type="text"
              label="Name"
              placeholder="Name"
              required
              v-model="personalFormValues.personalName"
              :val="personalFormValues.personalName"
              :error="personalFormErrors.personalName"
              @keyup="validateAt('personalName')"
            />
          </div>
          <div class="info-input-wrapper col half">
            <diyobo-input
              type="text"
              label="Email"
              placeholder="Email"
              required
              v-model="personalFormValues.personalEmail"
              :val="personalFormValues.personalEmail"
              :error="personalFormErrors.personalEmail"
              @keyup="validateAt('personalEmail')"
            />
          </div>
          <div class="info-input-wrapper col half">
            <diyobo-input
              type="text"
              label="Website"
              placeholder="Website"
              required
              v-model="personalFormValues.personalWebsite"
              :val="personalFormValues.personalWebsite"
              :error="personalFormErrors.personalWebsite"
              @keyup="validateAt('personalWebsite')"
            />
          </div>
          <div class="info-input-wrapper col half">
            <diyobo-input
              id="verification-phone-number"
              type="text"
              label="Phone Number"
              placeholder="Phone Number"
              required
              v-model="personalFormValues.personalPhone"
              :val="personalFormValues.personalPhone"
              :error="personalFormErrors.personalPhone"
              @keyup="validateAt('personalPhone')"
            />
          </div>
          <div class="info-input-wrapper col half">
            <image-uploader
              type="venue"
              label="Photo ID Verification 1"
              sublabel="Please provide a government issued ID. Requires PNG or JPG/JPEG"
              required
              :bus="bus"
              :componentCount="5"
              :info="personalFormValues.verificationImage1"
              :oldEvent="oldEvent"
              :key="50"
              ref="verificationImage"
              v-on:data="uploadImages('verificationImage1', ...arguments)"
              v-on:remove-image="removeImg('verificationImage1')"
              popUpContent="Image Dimensions should be between 800px x 400px and 600px x 300px."
              popUpLabel="Verification Photo"
              :error="personalFormErrors.verificationImage1"
            />
          </div>
          <!-- <div class="image-input-wrapper second col half">
            <image-uploader
              type="venue"
              label="Photo ID Verification 2"
              sublabel="Requires PNG or JPG/JPEG"
              required
              :bus="bus"
              :componentCount="5"
              :info="personalFormValues.verificationImage2"
              :oldEvent="oldEvent"
              :key="50"
              ref="verificationImage2"
              v-on:data="uploadImages('verificationImage2', ...arguments)"
              v-on:remove-image="removeImg('verificationImage2')"
              popUpContent="Image Dimensions should be between 800px x 400px and 600px x 300px."
              popUpLabel="Verification Photo"
              :error="personalFormErrors.verificationImage2"
            />
          </div> -->
        </div>
        <div
          v-if="verificationType === 'code'"
          class="verification-field-ctn row"
        >
          <div class="info-input-wrapper col flex center full">
            <div class="">
              <diyobo-input
                type="text"
                label="Code"
                placeholder="Code"
                required
                v-model="codeFormValues.code"
                :val="codeFormValues.code"
                :error="codeFormErrors.code"
                @keyup="validateAt('code')"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col flex center full">
            <diyobo-button txt="Submit" class="primary" @click="saveData" />
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="!coordVerified" class="verification-body">
      <Card class="card-container">
        <div class="general-info-title">
          <h3 class="general-title">Hang Tight ...</h3>

          <div class="row">
            <div class="col full flex center">
              <img src="/img/placeholders/not-verified.gif" alt="" />
            </div>
            <div class="col full flex center">
              <p class="col full info-text">
                Thank you for submitting your identity protection information.
                Our team of geniuses and nerds are going to verify the
                information that you've submitted. Either you'll receive a
                follow-up email from us with verification confirmed or we'll
                contact you for more details. This process takes less then 2
                business days and is often faster.
                <br />
                <br />
                Now that we have verified your identity you can continue to
                setup your first brand and then list your first event.
              </p>
            </div>
            <!-- <div class="col full flex center">
              <p class="col full info-text">
                Now that we have verified your identity you can continue to
                setup your first brand and then list your first event.
              </p>
            </div> -->
            <div class="col full flex center btn-container">
              <diyobo-button txt="Continue" class="primary" disabled />
            </div>
          </div>
        </div>
      </Card>
    </div>
    <div v-else class="verification-body">
      <!-- <h1 class="verification-text">You are Verified!</h1> -->

      <Card class="card-container">
        <div class="general-info-title">
          <h3 class="general-title">You're Verified</h3>

          <div class="row">
            <div class="col full flex center">
              <img src="/img/placeholders/verified.gif" alt="" />
            </div>
            <div class="col full flex center">
              <p class="col full info-text">
                Thank you for verifying your identity, you've helped make
                INCREDEVENT safer and more reliable.

                <br />
                Now that we have verified your identity you can continue to
                setup your first brand and then list your first event.
              </p>
            </div>
            <div class="col full flex center btn-container">
              <diyobo-button
                txt="Continue"
                class="primary"
                @click="$emit('nextPage')"
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import ImageUploader from "../../components/imageUploaders/TTButtonImageUploader.vue";
import { inputPhoneNumberOnly } from "@/helpers/input-formats.js";
import * as yup from "yup";
import DiyoboButton from "@/components/DiyoboButton.vue";

const VENUE_VALIDATION_SCHEMA = yup.object({
  venueName: yup.string().required("Venue Name is required."),
  venueWebsite: yup.string().required("Venue Website is required."),
  managerName: yup.string().required("Venue Manager's Name is required."),
  venuePhone: yup.string().required("Venue Manager's Phone Number is required.")
  // verificationImage: yup
  //   .object()
  //   .test(
  //     "has-fields",
  //     "Photo ID is required.",
  //     (value, context) => !!value.image
  //   )
  //   .required("Photo ID is required.")
});

const PERSONAL_VALIDATION_SCHEMA = yup.object({
  personalName: yup.string().required("Name is required."),
  personalWebsite: yup.string().required("Website is required."),
  personalEmail: yup.string().required("Email is required."),
  personalPhone: yup.string().required("Phone Number is required."),
  verificationImage1: yup
    .object()
    .test(
      "has-fields",
      "Photo ID 1 is required.",
      (value, context) => !!value.image
    )
    .required("Photo ID 1 is required.")
  // verificationImage2: yup
  //   .object()
  //   .test(
  //     "has-fields",
  //     "Photo ID 2 is required.",
  //     (value, context) => !!value.image
  //   )
  //   .required("Photo ID 2 is required.")
});

const CODE_VERIFICATION_SCHEMA = yup.object({
  code: yup.string().required("Code is required.")
});

export default {
  name: "brand-verification",
  components: {
    DiyoboInput,
    ImageUploader,
    DiyoboButton
  },
  props: {
    companyBus: Object,
    info: Object,
    coordVerified: Boolean
  },
  data() {
    return {
      objToSend: null,
      verificationType: "",
      verificationTypes: [
        {
          label: "Contact My Next Venue for Verification (Recommended)",
          value: "venue"
        },
        { label: "Contact Me for Verification", value: "personal" },
        { label: "I have a Verification Code", value: "code" }
      ],
      errors: [
        {
          name: "verificationType",
          display: "Verification Method",
          error: false
        }
      ],
      bus: new Vue(),
      oldEvent: {},
      venueFormValues: {
        venueName: "",
        venueWebsite: "",
        managerName: "",
        venuePhone: "",
        verificationImage: {}
      },
      venueFormErrors: {
        venueName: "",
        venueWebsite: "",
        managerName: "",
        venuePhone: "",
        verificationImage: ""
      },
      personalFormValues: {
        personalName: "",
        personalWebsite: "",
        personalEmail: "",
        personalPhone: "",
        verificationImage1: {},
        verificationImage2: {}
      },
      personalFormErrors: {
        personalName: "",
        personalWebsite: "",
        personalEmail: "",
        personalPhone: "",
        verificationImage1: "",
        verificationImage2: ""
      },
      codeFormValues: {
        code: ""
      },
      codeFormErrors: {
        code: ""
      }
    };
  },
  watch: {
    verificationType(value, oldValue) {
      if (oldValue === "" && value !== "code") {
        this.$nextTick(() => {
          const phoneInputElement = document.getElementById(
            "verification-phone-number"
          );
          inputPhoneNumberOnly(phoneInputElement, this.setVenuePhoneNumber);
        });
      } else {
        Object.keys(this.venueFormErrors).forEach(k => {
          this.venueFormErrors[k] = "";
        });
        Object.keys(this.personalFormErrors).forEach(k => {
          this.personalFormErrors[k] = "";
        });
        Object.keys(this.codeFormErrors).forEach(k => {
          this.codeFormErrors[k] = "";
        });
      }
    }
  },
  computed: {
    incomplete() {
      return !this.info.type && !this.info.venueName;
    }
  },
  methods: {
    validateAt(field) {
      if (this.verificationType === "venue") {
        VENUE_VALIDATION_SCHEMA.validateAt(field, this.venueFormValues)
          .then(() => {
            this.venueFormErrors[field] = "";
          })
          .catch(err => {
            this.venueFormErrors[field] = err.message;
          });
      } else if (this.verificationType === "personal") {
        PERSONAL_VALIDATION_SCHEMA.validateAt(field, this.personalFormValues)
          .then(() => {
            this.personalFormErrors[field] = "";
          })
          .catch(err => {
            this.personalFormErrors[field] = err.message;
          });
      } else if (this.verificationType === "code") {
        CODE_VERIFICATION_SCHEMA.validateAt(field, this.codeFormValues)
          .then(() => {
            this.codeFormErrors[field] = "";
          })
          .catch(err => {
            this.codeFormErrors[field] = err.message;
          });
      }
    },
    setVenuePhoneNumber(input) {
      if (this.verificationType === "venue") {
        this.venueFormValues.venuePhone = input;
      } else if (this.verificationType === "personal") {
        this.personalFormValues.personalPhone = input;
      }
    },
    uploadImages(key, value) {
      console.log(key, value);
      if (this.verificationType === "venue") {
        this.venueFormValues[key] = value;
      } else if (this.verificationType === "personal") {
        this.personalFormValues[key] = value;
      }

      this.validateAt(key);
    },
    error(input) {
      let error = this.errors.find(e => e.name === input);
      return error && error.error;
    },
    async validate() {
      this.errors[0].error = !this.verificationType;

      if (this.verificationType === "venue") {
        try {
          await VENUE_VALIDATION_SCHEMA.validate(this.venueFormValues, {
            abortEarly: false
          });
        } catch (error) {
          error.inner.forEach(err => {
            this.venueFormErrors[err.path] = err.message;
          });

          console.log(error.inner);
          return false;
        }
      } else if (this.verificationType === "personal") {
        try {
          await PERSONAL_VALIDATION_SCHEMA.validate(this.personalFormValues, {
            abortEarly: false
          });
        } catch (error) {
          error.inner.forEach(err => {
            this.personalFormErrors[err.path] = err.message;
          });

          console.log(error.inner);
          return false;
        }
      } else if (this.verificationType === "code") {
        try {
          await CODE_VERIFICATION_SCHEMA.validate(this.codeFormValues, {
            abortEarly: false
          });
        } catch (error) {
          error.inner.forEach(err => {
            this.codeFormErrors[err.path] = err.message;
          });

          console.log(error.inner);
          return false;
        }
      }

      return !this.errors.some(e => e.error);
    },
    async saveData() {
      let didIFail = false;
      const valid = await this.validate();
      if (valid) {
        let objToSend = {
          companyObj: {
            type: this.verificationType,
            ...this.venueFormValues,
            ...this.personalFormValues,
            ...this.codeFormValues
          }
        };

        objToSend.companyObj.verificationImage = undefined;
        objToSend.companyObj.verificationImage1 = undefined;
        objToSend.companyObj.verificationImage2 = undefined;

        objToSend.type = "verificationInfo";
        objToSend.userEmail = this.$store.state.user.email;
        this.objToSend = objToSend;

        this.saveVerificationInfo();
        // let objToValidate = ["verificationInfo", objToSend];

        // this.$emit("send-data", ...objToValidate);
      } else {
        let objToSend = ["verificationInfo", true];

        this.$emit("send-to-errors", ...objToSend);
      }

      // return didIFail;
    },
    saveVerificationInfo() {
      let objToSend = this.objToSend;
      const data = new FormData();

      data.append("companyObj", JSON.stringify(objToSend.companyObj));
      data.append("type", "verificationInfo");

      if (this.verificationType === "venue") {
        data.append("image", this.venueFormValues.verificationImage.imageFile);
      } else if (this.verificationType === "personal") {
        data.append(
          "image1",
          this.personalFormValues.verificationImage1.imageFile
        );
        data.append(
          "image2",
          this.personalFormValues.verificationImage2.imageFile
        );
      }

      this.$axios
        .post("/company/save-verification-info", data)
        .then(response => {
          let data = response.data;

          if (data.success) {
            this.$emit("success", data);
          } else {
            this.$bus.$emit("alert", {
              title: "Wrong Code",
              msg:
                "Valid codes can only be obtained through INCREDEVENT management and staff. If you have not gotten a code then you must use other forms of verification."
            });
          }
        })
        .catch(error => console.log(error));
    }
  },
  mounted() {
    if (this.companyBus) {
      this.companyBus.$on("save-company-info", async args => {
        const valid = true; // await this.validate();
        if (valid) {
          let objToSend = ["verificationInfo", false];
          await this.saveData();
          this.$emit("send-to-errors", ...objToSend);
        } else {
          let objToSend = ["verificationInfo", true];
          this.$emit("send-to-errors", ...objToSend);
          // window.scrollTo(0,1000)
        }
      });
    }
  }
};
</script>

<style lang="less">
.verification-wrapper {
  .verification-header {
    background: var(--primary-green);
    color: white !important;
    border-radius: 10px;
    padding: 16px;
    margin: 20px 0;

    .verification-title {
      color: white;
      margin: 0;
      font-size: 22px;
      text-align: center;
      margin-bottom: 16px;
    }
  }
  .verification-body {
    .card {
      padding: 24px;
    }
    .general-title {
      text-align: center;
      font-size: 24pt;
      color: var(--headers);
      margin: 0 0 24px;
    }
    .info-text {
      margin: 24px 0;

      font-size: 16px;
      text-align: center;
    }

    // .btn-container {
    //   margin: 0 0 24px;
    // }
    .row.verification-field-ctn {
      // justify-content: space-between;
      // width: initial !important;
      // margin-left: initial !important;

      // display: grid !important;
      // grid-template-columns: repeat(auto-fit, minmax(264px, 1fr));
      // grid-column-gap: 15px;
      .info-input-wrapper {
        display: block;
        position: relative;
        // width: calc(350px - 1em);
      }
      .image-input-wrapper {
        //square style
        //width: 399px;
        //margin-right: 29%;
        //full width style
        ///width: 718px;
        display: block;
        position: relative;
        // width: calc(350px - 1em);
        // margin-right: 34.8%;
        .verification-label.error {
          color: #e60022;
        }
        .uploader-wrapper {
          margin-bottom: 1.5em;

          .title-ctn {
            top: -1%;
          }
          .title-spacer {
            height: 30px;
          }
        }
        // .venue-img-wrapper {
        //   .venue-file-upload-wrapper {
        //     border-color: #807b7b;
        //     &:hover {
        //       border-color: #e3d2d2;
        //     }
        //   }
        // }
        .verification-label {
          display: block;
          flex-basis: 100%;
          margin-bottom: 0.5em;
          // font-family: roboto, sans-serif;
          font-size: 16px;
          font-weight: 200;
        }
        .verification-label > b {
          padding-left: 2px;
          color: @colors[input-required];
        }

        // this is a scuffed workaround for the label being positioned wrong somehow
        @media only screen and (min-width: 710px) {
          &.second {
            .empty_img {
              left: 15%;
            }
          }
        }
      }
    }
    h1.verification-text {
      color: white;
      text-align: center;
    }
    p.verification-part {
      text-align: center;
      font-size: 14px;
      color: #4eb95e;
      font-weight: bold;
    }
    .verification-title-text {
      color: #4eb95e !important;
    }
  }
}
@media only screen and (max-width: 710px) {
  .verification-wrapper {
    .verification-body {
      .verification-field-ctn {
        .info-input-wrapper {
          width: 100%;
        }
        .image-input-wrapper {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}
</style>
